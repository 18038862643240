<template>
  <div>
    <div class="tg_menu_ber_boolong_nsx" v-loading="loading">
      <div class="container">
        <ul>
          <li><a @click="redirectToCalendar()">Booking calendar</a></li>
          <li><a href="javascript:void(0)">My booking</a></li>
        </ul>
      </div>
    </div>
    <div class="" :class="{'tab-space': addClassFooter}">
      <div class="container">
        <h2 style="font-size: 23px;" v-if="!dataDetail.id" class="error-custom-h1">{{notFoundId}}</h2>
      </div>
    </div>
    <div class="container" v-if="dataDetail && dataDetail.customer">
      <div class="tg_new_booking_nsx tg_my_booking_nsx tg_my_booking_ver2_nsx">
        <div class="text_my_booking_hd">
          <div class="text_my_booking_ft">
            <h3><span>予約番号：{{ dataDetail.code }}</span>
              <a style="cursor: text;" v-if="this.dataDetail.status == 1" href="#">確認済</a>
              <a style="cursor: text;" v-if="this.dataDetail.status == 2" href="#">キャンセル</a>
              <a style="cursor: text;" v-if="this.dataDetail.status == 3" href="#">完了</a>
              <a style="cursor: text;" v-if="this.dataDetail.status == 4" href="#">キャンセル完了</a>
              <a style="cursor: text;" v-if="this.dataDetail.status == 5" href="#">決済待ち</a>
            </h3>
            <p>お客様情報</p>
            <ul>
              <li>
                <label>お名前</label>
                <strong>{{ dataDetail.customer.name }}</strong>
              </li>
              <li>
                <label>電話番号</label>
                <strong>{{ dataDetail.customer.phone }}</strong>
              </li>
              <li>
                <label>メールアドレス</label>
                <strong>{{ dataDetail.customer.email }}</strong>
              </li>
            </ul>
          </div>
          <div class="text_my_booking_ft">
            <p>予約情報</p>
            <ul>
              <li>
                <label>予約情報</label>
                <strong>{{ this.dataDetail.description }}</strong>
              </li>
              <li>
                <label>利用予定人員</label>
                <strong>{{ this.dataDetail.people_number }}人</strong>
              </li>
              <li>
                <label>利用日時</label>
                <strong>{{ this.strDateBooking() }}</strong>
              </li>
            </ul>
            <div class="tg_price_booking_nsx">
              <p><span>利用料金</span> <strong> {{ this.formatNumber(this.dataDetail.price_room) }}円</strong></p>
            </div>
          </div>
        </div>
        <div v-if="this.dataDetail.order_staff.length" class="tg_my_price_hd">
          <h3>スタッフ/Staff</h3>
          <h5 v-for="item in this.dataDetail.order_staff" :key="item.id">
            <p>{{ item.name }}</p><span><i>数量：{{ item.quantity }}</i><u>{{ formatNumber(item.price) }}円</u></span>
          </h5>
        </div>
        <div v-if="this.dataDetail.order_staff.length" class="tg_price_booking_nsx">
          <p><span>スタッフ料金</span> <strong>{{ this.sumPriceStaff() }}円</strong></p>
        </div>
        <div v-if="this.dataDetail.order_equipment.length" class="equipment">
          <div class="tg_my_price_hd">
            <h3>機材レンタル</h3>
            <div v-for="item in this.dataDetail.order_equipment" :key="item.id">
              <h5>
                <p>{{item.name}}</p>
                <span>
                    <i>数量：{{item.quantity}}</i>
                    <u>{{formatNumber(item.price)}}円</u>
                  </span>
              </h5>
              <ul v-html="renderEquiqment(item.data)"></ul>
            </div>
          </div>
          <div v-if="this.dataDetail.order_equipment.length" class="tg_price_booking_nsx">
            <p>
              <span>機材レンタル費</span>
              <strong>{{this.sumPriceEquipment()}}円</strong>
            </p>
          </div>
        </div>
        <div class="tg_total_price_nsx">
          <div class="text_total_price_ft">
            <p><span>合計</span><strong>{{ this.formatNumber(this.dataDetail.amount) }}円</strong></p>
          </div>
          <div class="bt_price_total_nsx bt_price_total_ver2_nsx">
            <router-link class="cus-btn" style="background-color: black; color: white" size="small" :to="{ name: 'calendar'}">戻る</router-link>
            <a class="btn-cancel-booking" type="button" size="small" v-if="this.dataDetail.status !== 5" @click="showModal()" v-bind:class="{'button disabled':checkTime}">予約のキャンセル</a>
            <a size="small" class="btn-payment" v-else style="background-color: #f69601;color: white;border: none;" type="button" @click.prevent="handleCheckTimePayment()">決済</a>
          </div>
          <div class="text_note_price_nsx">
            <p>※ご利用当日ではスタッフを手配することが出来ません。必ずスタッフを選択してください。</p>
            <p>※ご決済を15分過ぎた場合は、自動的にキャンセルになりますので、予めご了承ください</p>
            <p>※キャンセル料、期限は以下の通りです。</p>
            <ul>
              <li>30日～15日　 30％</li>
              <li>14日～8日　　50％　</li>
              <li>7日前～当日　100％</li>
            </ul>
            <p>キャンセル後、30日以内に返金を振込させていただきます。</p>
          </div>
        </div>
      </div>
    </div>
    <!--   modal room -->
    <div id="confirm-cancel-booking" class="popup_bos18" v-bind:class="{'popup':checkPopupCancelBooking}" v-loading="loadingPopup">
      <div class="content_popup">
        <div class="form_group">
          <p>キャンセル料が発生するかもしれませんが、予約をキャンセルしますか？</p>
          <button class="custom-button" @click.prevent="closeModal()" type="submit">いいえ</button>
          <button class="custom-button-2" @click.prevent="comfirmCancelBooking()" type="submit">はい</button>
        </div>
      </div>
    </div>
    <!-- end dialog room -->

    <!--   modal payment error -->
    <div id="payment-error" class="popup_bos18" v-bind:class="{'popup':checkPopupPaymentBooking}" v-loading="loadingPopup">
      <div class="content_popup">
        <div class="form_group">
          <p>{{$t('ER_14')}}</p>
          <button class="custom-button" @click.prevent="closeModalPayment()" type="submit">いいえ</button>
        </div>
      </div>
    </div>
    <!-- end dialog payment error -->
  </div>


</template>

<script>
import {mapGetters, mapActions} from "vuex";
import moment from "moment";

import {DETAIL_ORDER, CHANGE_STATUS, PROCESS_BOOKING} from "@/core/services/store/order.module";
import {STATUS_CODE} from "@/core/config/constant";

/* Status code */
const {AUTHENTICATE, ERROR_SERVER, SUCCESS} = STATUS_CODE;

export default {
  name: "DetailOrder",
  data() {
    return {
      loading: false,
      loadingPopup: false,
      dialogDelete: false,
      end_time: '',
      checkTime: false,
      formChangeStatus: {},
      check: false,
      flag: false,
      checkPopupCancelBooking: false,
      checkPayment: false,
      checkPopupPaymentBooking: false,
      notFoundId: '',
      addClassFooter: false
    };
  },
  computed: {
    ...mapGetters(['dataDetail'])
  },
  async created() {
    let id = this.$route.params.id;
    await this.getDetail(id);
    if(!this.dataDetail.amount) {
        this.addClassFooter = true;
        this.notFoundId = 'この予約は存在しません。';
    } else if(!this.dataDetail.amount){
      this.addClassFooter = true;
      this.notFoundId = 'この予約は存在しません。';
    } else {
      this.addClassFooter = false;
    }
    this.handleDateTime();
  },

  methods: {
    async handleCheckTimePayment() {
      const current = new Date();
      const timeBooking = new Date(this.dataDetail.created_at);
      var diff =(current.getTime() - timeBooking.getTime()) / 1000;
      diff /= 60;
      var result = Math.abs(Math.round(diff));
      // if(result >= 15) {
      //   this.checkPopupPaymentBooking = true;
      // }
      let data = await this.$store.dispatch(PROCESS_BOOKING, {'order_id': this.$route.params.id});
      if (data.code == SUCCESS) {
        window.location.href = data.data;
      }
    },
    closeModalPayment() {
      this.checkPopupPaymentBooking = false;
      this.$router.push({ name: 'calendar' })
      this.loadingPopup = true;
      this.$store.dispatch(CHANGE_STATUS, this.dataDetail);
      this.loadingPopup = true;
    },
    handleDateTime() {
      const dateObj = new Date()
      const date = dateObj.toLocaleDateString()
      const currentDate = new Date(date)
      const dateBooking = new Date(this.dataDetail.date)
      const currentTime = moment(dateObj).format("hh:mm")
      const timeBooking = this.dataDetail.end_time;
      if ((timeBooking < currentTime || dateBooking < currentDate) || this.dataDetail.status == 3 || this.dataDetail.status == 2 || this.dataDetail.status == 4) {
        this.checkTime = true;
      }
    },
    showModal() {
      this.checkPopupCancelBooking = true;
    },
    closeModal() {
      this.checkPopupCancelBooking = false;
    },

    async getDetail(id) {
      this.loading = true;
      await this.$store.dispatch(DETAIL_ORDER, id);
      this.loading = false;
    },

    strDateBooking() {
      let arr = this.dataDetail.date.split("-");
      let date =
          arr[0] +
          "年" +
          arr[1] +
          "月" +
          arr[2] +
          "日 " +
          this.dataDetail.start_time +
          "~" +
          this.dataDetail.end_time;

      return date;
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    sumPriceStaff() {
      let amount = 0;
      this.dataDetail.order_staff.map(x => (amount += x.price));
      this.price_staff = amount;
      return this.formatNumber(amount);
    },

    sumPriceEquipment() {
      let amount = 0;
      this.dataDetail.order_equipment.map(x => (amount += x.price));
      this.price_equipment = parseInt(amount);
      return this.formatNumber(amount);
    },

    renderEquiqment(data) {
      let arr = JSON.parse(data);
      let html = '';
      for (let i = 0; i < arr.length; i++) {
        html += `<li><span>${arr[i].equipment_name}</span><i>数量：${arr[i].equipment_quantity}</i></li>`
      }
      return html;
    },
    async comfirmCancelBooking() {
      this.loading = true;
      this.loadingPopup = true;
      this.$bvModal.hide('confirm-cancel-booking');
      const data = await this.$store.dispatch(CHANGE_STATUS, this.dataDetail);
      if (data.code === STATUS_CODE.SUCCESS) {
        this.$router.push({ name: 'calendar', params: {flag: true, code: this.dataDetail.code}  })
        this.check = true;
        this.loading = false;
      }
      else {

        this.notifyError(this.$t("ER_19"));
        this.loadingPopup = false;
        this.checkPopupCancelBooking = false;
        this.loading = false;
      }
    },

    redirectToCalendar() {
      this.$router.push({ name: 'calendar'})
    }
  }
};
</script>
<style>
.fade {
  opacity: 1 !important;
}

.text-center {
  text-align: center !important;
  border: none;
}
.disabled:active{
  pointer-events: none;
}
a.button.disabled{
  cursor: no-drop !important;
}

.bt_price_total_nsx.bt_price_total_ver2_nsx {
  cursor: pointer;
}

body {
  background-color: #fff;
}

button.btn.btn-back.btn-secondary, button.btn.btn-back.btn-secondary:hover {
  background-color: #AAAAAA !important;
  color: #fff !important;
}
button.btn.btn-back.btn-secondary, button.btn.btn-primary.btn-secondary {
  width: 67px !important;
  height: 38.389px !important;
}

button.btn.btn-primary.btn-secondary {
  background-color: #3699ff !important;
  color: #fff !important;
}
button.btn.btn-primary.btn-secondary:hover {
  background-color: #027db4 !important;
  color: #fff !important;
}
.disabled {
  cursor: no-drop !important;
}
.popup .form_group {
  padding: 25px;
  padding: 55px 55px 20px 46px;
  background: #fff;
  border-radius: 10px;
}
button.custom-button {
  margin: 18px !important;
  background-color: #AAAAAA !important;
}
.popup_bos18 {
  display: none;
}
button.custom-button-2 {
  width: 103px;
}
.text_note_price_nsx>p.tg_sos {
  color: #000;
}
.tab-space {
  height: calc(100vh - 450px);
  margin-top: 150px;
}
.bt_price_total_nsx.bt_price_total_ver2_nsx a:last-child::before {
  background: url("../../../../public/template/images/bt_tab_price.png") no-repeat !important;
}
@media only screen and (max-width: 750px) and (min-width: 300px) {

  .bt_price_total_nsx {
    display: table !important;
    margin: 30px auto !important;
    max-width: 330px !important;
    width: 100% !important;
  }
  .bt_price_total_nsx>a {
    font-size: 18px !important;
    line-height: 26px !important;
    color: #fff;
    background: #222222;
    font-weight: 500;
    display: table;
    width: 100%;
    max-width: 150px;
    text-align: center;
    padding: 15px 25px 15px 0px;
    float: left;
    margin: 0px 15px 0px 0px;
    border-radius: 10px;
    position: relative;
  }
  @media (max-width: 767px){
    .bt_price_total_nsx>a {
      max-width: 226px !important;
      white-space: nowrap;
    }
  }
}
.tg_new_booking_nsx.tg_my_booking_nsx .tg_total_price_nsx .bt_price_total_nsx a.btn-payment:last-child::before {
  background: url("../../../../public/template/images/bt_next_price.png") no-repeat !important;
  top: 20px;
}
</style>
